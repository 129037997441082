// extracted by mini-css-extract-plugin
export var base = "PageNotFound-module--base--3-Nuh";
export var rightAlignedContainer = "PageNotFound-module--rightAlignedContainer--eFh6X";
export var rightAlignedContainerSmall = "PageNotFound-module--rightAlignedContainerSmall--IqWEp";
export var leftAlignedContainer = "PageNotFound-module--leftAlignedContainer--Vbh3h";
export var blogContainer = "PageNotFound-module--blogContainer--27GMG PageNotFound-module--base--3-Nuh";
export var container = "PageNotFound-module--container--161Vj PageNotFound-module--base--3-Nuh";
export var largeContainer = "PageNotFound-module--largeContainer--1mCwQ PageNotFound-module--base--3-Nuh";
export var mobileSmallPadding = "PageNotFound-module--mobileSmallPadding--de9fp";
export var fullWidthMobile = "PageNotFound-module--fullWidthMobile--pT_8T";
export var gridWithSidebar = "PageNotFound-module--gridWithSidebar--2QH-N";
export var noMaxWidth = "PageNotFound-module--noMaxWidth--2ByEn";
export var wrap = "PageNotFound-module--wrap--2bs6s PageNotFound-module--container--161Vj PageNotFound-module--base--3-Nuh";
export var title = "PageNotFound-module--title--1diWZ";
export var icon = "PageNotFound-module--icon--_gNHm";
export var error = "PageNotFound-module--error--1US35";
export var bottle = "PageNotFound-module--bottle--liMTV";
export var wave = "PageNotFound-module--wave--3eZH_";
export var sway = "PageNotFound-module--sway--3wJG6";
export var helpText = "PageNotFound-module--helpText--3lcqy";